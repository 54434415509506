import {
  Highlight,
  InstantSearch,
  SearchBox,
  RefinementList,
  ToggleRefinement,
  Snippet,
  Stats,
  ClearRefinements,
  SortBy,
  Configure
} from "react-instantsearch";
import { InfiniteHits } from './InfiniteHits';
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

import './App.css';

import moment from 'moment';
import 'moment/locale/fr';  // Importer la localisation française
moment.locale('fr');

const { searchClient, setMeiliSearchParams } = instantMeiliSearch(
  "https://i.r.lelombrik.net",
  "2d36c646b4ba90e885fd3426ca20d0cbaf8173894efcbf0c5b7cc324f04c6446",
  {
    finitePagination: false
  }
)


function App() {
  return (
    <div className="container">
      <InstantSearch indexName="medias:id:desc" primaryKey="id" searchClient={searchClient} routing={true} >
        <Configure
          analytics={false}
        />
        <div className="left-panel">
          <div className="logo">
            <img src="/main-logo.png" alt="Logo" /><span>Recherche <br />lelombrik</span>
          </div>
          <div className="inner-left-panel">
            <ClearRefinements translations={{ resetButtonText: 'Réinitialiser les filtres' }} />
            <RefinementList attribute="type" />
            <hr class="hit-hr" />
            <ToggleRefinement attribute="sexy" label='Fichiers sexy' on='True' off='False' />
            <ToggleRefinement attribute="gore" label='Fichiers violent' on='True' off='False' />
            <ToggleRefinement attribute="featured" label="Fichiers en sélection" />
            <hr class="hit-hr" />
            <SortBy
              label="test"
              items={[
                { value: "medias", label: "Tri par vote" },
                { value: "medias:note:desc", label: "Les + appréciés" },
                { value: "medias:note:asc", label: "Les - appréciés" },
              ]}
            />
            <SortBy
              label="test"
              items={[
                { value: "medias", label: "Tri par âge" },
                { value: "medias:id:asc", label: "Les + vieux" },
                { value: "medias:id:desc", label: "Les + récents" },
              ]}
            />            
          </div>
        </div>
        <div className="right-panel">
          <SearchBox autoFocus={true} placeholder="Rechercher sur lelombrik.net" />
          <Stats />
          <InfiniteHits hitComponent={Hit} />
        </div>
      </InstantSearch>
    </div>
  );
}

function Hit({ hit }) {
  const readableDate = moment.unix(hit.date).fromNow();
  return (
    <a href={`https://lelombrik.net/${hit.id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
      <article key={hit.id}>
        <div className={`hit-name ${hit.type}`}>
          {hit.sexy && <img className="hit-icon" src='/croix-rose.png' alt='Croix rose' />}
          {hit.gore && <img className="hit-icon" src='/croix-noire.png' alt='Croix noire' />}
          <Highlight attribute="title" hit={hit} />
        </div>
        <img className="hit-img" src={hit.thumbnail_url} alt={hit.title} align="left" loading="lazy"/>
        <div className="hit-description">
          <Snippet attribute="description" hit={hit} />
        </div>
        <hr className="hit-hr"/>
        <div className="hit-info">Note : <Snippet attribute="note" hit={hit} /></div>
        <div className="hit-info">Publié {readableDate}</div>
      </article>
    </a>
  );
}

export default App;
